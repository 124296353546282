<template>
  <div>
    <h1>{{ t("get_app_page.download_our_app") }}</h1>

    <!-- TODO: локализовать -->
    <div style="margin:-12px;margin-bottom:0;" class="mb-3">
      <a
        href="https://play.google.com/store/apps/details?id=com.omoe&utm_source=omoe.ru%20website&utm_campaign=website&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
      >
        <img
          alt="Доступно в Google Play"
          src="../assets/store-badges/google.png"
          style="width: 185px;"
        />
      </a>
    </div>
    <div>
      <a
        href="https://apps.apple.com/ru/app/omoe-%D0%BE%D0%B1%D1%8A%D1%8F%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D1%8F/id1549513530?itsct=apps_box&amp;itscg=30200"
        style="display: inline-block; overflow: hidden; border-radius: 13px; width: 100%; margin: auto; padding: 0;"
      >
        <img
          src="../assets/store-badges/apple.svg"
          alt="Загрузите в App Store"
          style="border-radius: 13px; width: 165px;"
        />
      </a>
    </div>

    <p style="padding: 10px;margin: 10px;">
      Google Play и логотип Google Play являются товарными знаками корпорации
      Google LLC. App Store, iPhone и iPad являются товарными знаками Apple Inc.
    </p>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { useMeta } from "vue-meta";

export default {
  name: "GetApp",
  setup() {
    const { t } = useI18n();

    useMeta({
      title: t("get_app_page.title"),
      description: t("get_app_page.description"),
    });

    return {
      t,
    };
  },
};
</script>

<style scoped></style>
